import React from 'react';
import Expander from '../Expander/Expander';
import { ContainerPropTypes } from './Container.types';
const Container: React.FC<ContainerPropTypes> = ({
  containerRef,
  attr,
  className = '',
  children,
  background,
  id,
  elementId,
  HtmlTag = 'section'
}) => {
  return <HtmlTag ref={containerRef} id={elementId} data-type={attr} data-id={id} className={`relative z-[1] mx-auto mb-[4rem] max-w-[160rem] px-[2.4rem] md:mb-[6rem] md:px-[6.3rem] lg:mb-[8rem] lg:px-[6.4rem] xl:px-[12.8rem] 3xl:px-[4rem] 4xl:px-[0] ${className}`} data-sentry-element="HtmlTag" data-sentry-component="Container" data-sentry-source-file="Container.tsx">
      {children}
      {background && <Expander className={`absolute right-[0] top-[0] z-[-10] ${background}`}></Expander>}
    </HtmlTag>;
};
export default Container;