import React from 'react';
import { CommonPropTypes } from '../IconTypes';
const ArrowIOS: React.FC<CommonPropTypes> = ({
  className
}) => {
  return <svg className={className} width='52' height='51' viewBox='0 0 52 51' fill='none' xmlns='http://www.w3.org/2000/svg' data-sentry-element="svg" data-sentry-component="ArrowIOS" data-sentry-source-file="ArrowIOS.tsx">
      <circle cx='25.7557' cy='25.2518' r='25.2518' fill='#F9F9F9' data-sentry-element="circle" data-sentry-source-file="ArrowIOS.tsx" />
      <path d='M23.0539 17.9441L29.6754 24.6544C29.8563 24.8652 29.9648 25.0759 29.9648 25.2867C29.9648 25.4975 29.8925 25.6732 29.7477 25.8489L23.1262 32.5591C22.7644 32.9104 22.2217 32.9104 21.896 32.5942C21.5342 32.278 21.5342 31.7511 21.8598 31.3997L27.8662 25.2867L21.7875 19.1035C21.4618 18.7873 21.4618 18.2252 21.8237 17.909C22.1855 17.5928 22.7282 17.5928 23.0539 17.9441Z' fill='currentColor' data-sentry-element="path" data-sentry-source-file="ArrowIOS.tsx" />
    </svg>;
};
export default ArrowIOS;