import { AnyAction } from 'redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { useDispatch, useSelector, TypedUseSelectorHook } from 'react-redux';

import { store } from './store';
import rootReducer from '../reducers/reducers';

export type RootStateTypes = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type CombinedStateType = ReturnType<typeof rootReducer>;
export type TypedThunk<ReturnType = void> = ThunkAction<ReturnType, CombinedStateType, unknown, AnyAction>;
export type TypedDispatch = ThunkDispatch<RootStateTypes, unknown, AnyAction>;

export const useTypedAppSelector: TypedUseSelectorHook<RootStateTypes> = useSelector;
export const useTypedDispatch = () => useDispatch<TypedDispatch>();
